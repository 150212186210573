@import "../hsg";

// ==================================================================
// typography

h1, h2, h3, h4, h5, h6 {
    font-family: "Futura Bold", Roboto, sans-serif;
    color: #454545;
}

p, a, li, span, div {
    font-family: "Futura Medium", Roboto, sans-serif;
}

.breaking-news__attention {
    color: #454545;
}

.breaking-news {
    .share__button__icon {
        color: white;
    }
}

.news-teaser {
    .news-teaser__item {
        .news-teaser__title {
            font-family: "Futura Medium", Roboto, sans-serif;
            color: #454545;
        }
    }
}

.infobox__container .tabs--vertical {
    .btn-large {
        background: $secondary-color;
        color: color("grey", "darken-2") !important;
    }

	.tab .active {
		&::before {
			border-left: 3px solid color("grey", "darken-2");
		}

		.tab__title {
			color: #454545 !important;
		}

		.btn-large {
			color: white !important;
			background: color("grey", "darken-2");
		}
	}
}

.infobox__content {
    .infobox__content__button .btn {
        background: color("grey", "darken-2");
    }
}

.current-program__icons {
    .btn {
        background-color: #454545 !important;

        i {
            color: white !important;
        }
    }
}

.jetzt_live__playlist {
    .playlist__affiliate__icon {
        color: white;
    }
}

.current-program__title {
    color: #454545;
}

.current-program__subtitle {
    color: #454545;

    a {
        color: #454545;
    }
}

.breaker__topic {
    color: #454545;
}

.webradio-play i {
    color: white;
}

.webradio-title {
    color: white;
}

// ==================================================================
// header

.nav-wrapper {
    border-top: 3px solid white;
    background: $primary-color;
}

.sidenav-trigger i {
    color: #454545;
}

.sidenav-page {
    background: color("grey", "darken-2");

    .sidenav-close {
        font-family: "Futura Medium", Roboto, sans-serif;
        font-weight: bold;
        background: none;
        font-size: 3rem;

        i {
            display: none;
        }
    }

    .sidenav__navigation > li > a {
        font-size: 2.2rem !important;
    }

    .sidenav__navigation > li {
        li a {
            font-size: 1.6rem !important;
        }
    }

    ul, li, a {
        font-family: "Futura Medium", Roboto, sans-serif;
        font-weight: lighter !important;
        color: white !important;
    }
}

.nav-mobile-header img {
    margin-bottom: 7px;
    margin-top: 7px;
    height: 52px;
    width: auto;
}

.nav-icon-item * {
    color: #454545 !important;
}

.nav-background {
    background: $primary-color;
}

.webradio--header {
    flex-direction: row-reverse;

    .webradio-play {
        background: color("grey", "darken-2");
        overflow: hidden;
        border-radius: 0%;
        padding: rem(4px);
    }

    .webradio-title {
        background: color("grey", "base");
        padding: rem(4px);
    }

    .webradio-cover {
        margin-left: rem(18px);
    }
}

.nav-social-media-links {
    display: none;
    padding-left: rem(14px);

    @include media-breakpoint-up(xl) {
        display: flex;
    }


    a {
        margin-left: 1.125rem;
        position: relative;
        overflow: hidden;
        display: block;
        height: 48px;
        width: 48px;

        img {
            position: absolute;
            height: auto;
            width: 100%;
            left: 0;
            top: 0;
        }
    }
}

// ==================================================================
// floating action buttons

.floating-ivw-logo {
    display: none;
}

.floating-share-button {
    .fixed-action-btn {
        bottom: 228px;
        width: 56px;
        left: 23px;
    }
}

.floating-scroll-button {
    bottom: 153px;
    width: 56px;
    left: 23px;
}

.btn-floating {
    i {
		color: color("grey", "darken-3");
    }
}

// footer
.page-footer-floating {
    @include koelnGradient;
}

.page-footer-floating {
    &__web-channel {
        cursor: pointer;
        display: flex;

        &__icon {
			background: color("grey", "darken-2");
			
            i {
                font-size: 2.25rem;
                color: white;
            }
        }

        &__text {
            text-overflow: ellipsis;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            background: color("grey", "base");
            padding-right: rem(24px);
            max-width: 420px;

            h4 {
                margin-left: rem(24px);
                max-width: 320px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
                color: white;
            }
        }

    }
	
	&__team-name {
		&__wrapper {
			text-align: center;

			.team-name__upper {
				h3 {
					// color: white;
					color: black;
					font-size: 1.5rem;
					margin: 0;
				}
			}

			.team-name__lower {
				h3 {	
					// color: $secondary-color;
					color: black;
					font-size: 1.5rem;
					transform: rotate(0deg);
					margin: 0;
				}
			}
		}
	}
}

// ONLY FOR CLICK DUMMY !!!!
// TODO: Remove after click dummy
.nav-social-media-links-koeln {
    @include media-breakpoint-up(xl) {
        display: flex !important;
    }
}

nav.breadcrumb {
	a {
		&:hover {
			color: $secondary-color;
		}
	}
}

.loader {
	.btn & {
		border: .15rem solid rgba(color("grey", "darken-3"), 0.2);
		border-left: .15rem solid color("grey", "darken-3");
	}
}

.btn--icon__image path {
	fill: color("grey", "darken-3");
	stroke: color("grey", "darken-3");
}

// Quantyoo Login Sidenav
.sidenav .sidenav-login {
  &__link {
    color: color("grey", "darken-2") !important;
  }
}

.page-footer-floating__team-img {
	min-width: 219px !important;
	width: 219px !important;
}