/*** Colors ***/
$primary-color: color("primary", "base");
$secondary-color: color("secondary", "base");

$font-stack: "Roboto", sans-serif;
$font-stack-headlines: "Futura-Bold", sans-serif;
$transparent-layer-color: rgba(color("shades", "black"), 0.7);

/* Westfunk */
$widget-height: 550px !default;

$body-color: color("grey", "lighten-4");

$tabs-underline-color: $primary-color;
$border-color: color("grey", "lighten-2");

@mixin koelnGradient() {
	background: #ffbf00; /* Old browsers */
	background: -moz-linear-gradient(left, #ffbf00 0%, #ffff00 25%, #ffff00 75%, #ffbf00 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #ffbf00 0%,#ffff00 25%,#ffff00 75%,#ffbf00 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #ffbf00 0%,#ffff00 25%,#ffff00 75%,#ffbf00 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbf00', endColorstr='#ffbf00',GradientType=1 ); /* IE6-9 */
}

// 10. Forms
// ==========================================================================

// Text Inputs + Textarea
$input-border-color: color("grey", "lighten-1");
$input-focus-color: color("grey", "lighten-1");
$input-error-color: color("grey", "lighten-1");
$input-background: color("grey", "darken-3");

// Radio Buttons
$radio-fill-color: $primary-color;

// 24. Progress Bar
// ==========================================================================

$progress-bar-color: $primary-color;

/// Colors
$background-light: color("shades", "white");
$pagination-color: color("grey", "lighten-3");
$link-color: color("secondary", "base");
$tab-background: color("grey", "lighten-4");
$tab-background-active: color("grey", "lighten-3");
$card-link-color: $primary-color;

$button-raised-color: color("grey", "darken-3");